import React from "react";
import { graphql } from "gatsby";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

import Layout from "../layouts";

const styles = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [{ saturation: 36 }, { color: "#000000" }, { lightness: 40 }],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [{ visibility: "on" }, { color: "#000000" }, { lightness: 16 }],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [{ color: "#000000" }, { lightness: 20 }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{ color: "#000000" }, { lightness: 17 }, { weight: 1.2 }],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#000000" }, { lightness: 20 }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#000000" }, { lightness: 21 }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{ color: "#000000" }, { lightness: 17 }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#000000" }, { lightness: 29 }, { weight: 0.2 }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ color: "#000000" }, { lightness: 18 }],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{ color: "#000000" }, { lightness: 16 }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#000000" }, { lightness: 19 }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#000000" }, { lightness: 17 }],
  },
];

export class MapContainer extends React.Component {
  state = { bounds: null, showingInfoWindow: false };
  bounds = new this.props.google.maps.LatLngBounds();
  markersData = [];

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  onMarkerClick = (props, marker, e) => {
    console.log(marker);
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };
  constructor(props) {
    super(props);

    // Process graphql data
    this.props.data.projects.edges.forEach((element) => {
      const {
        node: {
          data: { Assignments = [], Latitude, Longitude, Name, Customer },
        },
      } = element;
      const employees = {};

      // Calculate number of unique employee names per project
      if (Assignments) {
        Assignments.forEach((assignment) => {
          if (assignment.data.Person && assignment.data.Active) {
            assignment.data.Person.forEach((person) => {
              employees[person.data.Name] = true;
            });
          }
        });
      }
      if (Name && Latitude && Longitude && employees && Customer[0]) {
        // Populate class variables
        this.markersData.push({
          orgName: Name,
          position: { lat: Latitude, lng: Longitude },
          employeeCount: Object.keys(employees).length,
          employees: Object.keys(employees),
          customerName: Customer[0].data.Name,
        });
      }
      this.bounds.extend({ lat: Latitude, lng: Longitude });
    });
  }

  componentDidMount() {
    // Fixme: Make map fit to bounds
    // This is supposed to rerender the component, which will rerender the subcomponent (Map), which
    // should fit the map to the bounds (runs fitBounds() behind the scenes) but by some reason this doesn't work.
    // I've tried to remove zoom and initialCenter props from Map since these are supposed to be recalculated by
    // fitBounds() but that doesn't work either.
    this.setState({ bounds: this.bounds });
  }

  render() {
    return (
      <Layout>
        <Map
          style={{ width: "77%", height: "87%" }}
          styles={styles}
          google={this.props.google}
          zoom={9}
          initialCenter={{ lat: 57.7, lng: 12.7 }}
          bounds={this.state.bounds}
          onMapClicked={this.onMapClicked}
        >
          {this.markersData.map(
            (
              { orgName, position, employeeCount, employees, customerName },
              i
            ) => (
              <Marker
                icon={{
                  url: `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_white${employeeCount}.png`,
                }}
                key={i}
                title={`${orgName} (${employeeCount})`}
                position={position}
                onClick={this.onMarkerClick}
                employees={employees}
                customerName={customerName}
              />
            )
          )}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
                paddingBottom: 10,
              }}
            >
              {this.state.activeMarker ? (
                <div>
                  <p style={{ color: "#000000" }}>
                    {this.state.activeMarker.customerName}
                  </p>
                  <hr />
                  <p style={{ color: "#000000" }}>
                    {this.state.activeMarker.title}
                  </p>
                  <ul style={{ paddingRight: 10 }}>
                    {this.state.activeMarker.employees.map(function (
                      employeeName,
                      i
                    ) {
                      return (
                        <li style={{ color: "#000000" }} key={i}>
                          {employeeName}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}

              <ul></ul>
            </div>
          </InfoWindow>
        </Map>
      </Layout>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCYdTLHGFKikjZGHE5WAXVBI0_3gm4NCkk",
  language: "sv",
})(MapContainer);

export const query = graphql`
  {
    projects: allAirtable(
      filter: {
        table: { eq: "Projects" }
        data: {
          Latitude: { ne: null }
          Longitude: { ne: null }
          Has_active_assignments: { gt: 0 }
        }
      }
    ) {
      edges {
        node {
          id
          data {
            Name
            Customer {
              data {
                Name
              }
            }
            Assignments {
              data {
                Active
                Person {
                  data {
                    Name
                  }
                }
              }
            }
            Latitude
            Longitude
          }
        }
      }
    }
  }
`;
